<mkad-wrapper-dialog
  iconLeft="user-gear"
  footerAlign="between"
  [header]="'app.shared.userProfile.title' | translate"
  (clickClose)="onClickClose(null)">
  @if (userForm) {
    <form body class="form_userProfile" [formGroup]="userForm">
      <nb-tabset>
        <nb-tab [tabTitle]="'app.shared.userProfile.tabs.stepProfile' | translate"
                [tabIcon]="{icon:'user', pack:'far'}" responsive>
          <div class="grid-col gap-2">
            <section class="field__wrap">
              <div class="flex flex-row flex-wrap">
                <div class="flex align-items-center justify-content-center m-2">
                  <div class="avatar-container">
                    @if (avatar) {
                      <div class="image-container"
                           [class.box-shadow-avatar]="!!avatar"
                           [ngStyle]="{backgroundImage: 'url(' + avatar + ')'}">
                      </div>
                    } @else {
                      <div class="image-container avatar-placeholder">
                      </div>
                    }
                    <label for="avatar">
                      <div class="icon-wrapper">
                        <input type="file" id="avatar" name="avatar" formControlName="avatar" accept="image/*"
                               (change)="onChangeFile($event)"/>
                        <nb-icon class="icon" icon="pencil" pack="far" />
                      </div>
                    </label>
                    @if (avatar) {
                      <button class="icon-remove" (click)="onRemoveFile()">
                        <nb-icon class="icon" icon="x" pack="far"/>
                      </button>
                    }
                  </div>
                </div>
                <div class="flex align-items-center justify-content-center m-2">
                  <div class="flex flex-column">
                    <div class="flex align-items-center ml-2">
                      <mkad-form-label
                        controlId="avatar"
                        [text]="'app.shared.userProfile.field.avatar.label' | translate"
                        [tooltip]="'app.shared.userProfile.field.avatar.tooltips' | translate"
                        [status]="statusField('avatar')"/>
                    </div>
                    <div class="flex align-items-center justify-content-center ml-2">
                      <label>{{ this.userData?.first_name }} {{ this.userData?.last_name }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="field__wrap">
              <mkad-form-label
                controlId="name"
                [text]="'app.shared.userProfile.field.name.label' | translate"
                [tooltip]="'app.shared.userProfile.field.name.tooltip' | translate"
                [status]="statusField('name')"/>
              <nb-form-field>
                <input fullWidth nbInput fieldSize="small" formControlName="name" id="name"
                       [placeholder]="'app.shared.userProfile.field.name.placeholder' | translate"
                       [nbTooltip]="'app.shared.userProfile.field.name.tooltip'|translate"
                       [status]="statusField('name')">
                <nb-icon nbSuffix icon="id-card" pack="far"/>
              </nb-form-field>
            </section>
            <section class="field__wrap">
              <mkad-form-label
                controlId="lastname"
                [text]="'app.shared.userProfile.field.lastname.label' | translate"
                [tooltip]="'app.shared.userProfile.field.lastname.tooltip' | translate"
                [status]="statusField('lastname')"/>
              <nb-form-field>
                <input fullWidth nbInput fieldSize="small" formControlName="lastname" id="lastname"
                       [placeholder]="'app.shared.userProfile.field.lastname.placeholder' | translate"
                       [nbTooltip]="'app.shared.userProfile.field.lastname.tooltip'|translate"
                       [status]="statusField('lastname')">
                <nb-icon nbSuffix icon="id-card" pack="far"/>
              </nb-form-field>
            </section>
            <section class="field__wrap">
              <mkad-form-label
                controlId="email"
                [text]="'app.shared.userProfile.field.email.label' | translate"
                [tooltip]="'app.shared.userProfile.field.email.tooltip' | translate"/>
              <nb-form-field>
                <input fullWidth nbInput fieldSize="small" formControlName="email" id="email"
                       [placeholder]="'app.shared.userProfile.field.email.placeholder' | translate"
                       [nbTooltip]="userForm.controls['email'].value">
                <nb-icon nbSuffix icon="envelope" pack="far"/>
              </nb-form-field>
            </section>
          </div>
        </nb-tab>
        <nb-tab tabTitle="{{'app.shared.userProfile.tabs.stepPreference' | translate}}"
                [tabIcon]="{icon:'folder-gear', pack:'far'}" responsive>
          <div class="grid-col gap-2">
            <section class="field__wrap">
              <mkad-form-label
                controlId="timeZone"
                [text]="'app.shared.userProfile.field.timeZone.label' | translate"
                [tooltip]="'app.shared.userProfile.field.timeZone.tooltip' | translate"
                [status]="statusField('timeZone')"/>
              <nb-form-field>
                <nb-select fullWidth [placeholder]="'app.shared.userProfile.field.timeZone.placeholder' | translate"
                           size="small" formControlName="timeZone" id="timeZone"
                           [nbTooltip]="'app.shared.userProfile.field.timeZone.tooltip'|translate"
                           [status]="statusField('timeZone')">
                  <nb-option *ngFor="let timeZone of timeZoneList" [value]="timeZone">{{ timeZone }}</nb-option>
                </nb-select>
              </nb-form-field>
            </section>
            <section class="field__wrap">
              <mkad-form-label
                controlId="message"
                [text]="'app.shared.userProfile.field.message.label' | translate"
                [tooltip]="'app.shared.userProfile.field.message.tooltip' | translate"
                [status]="statusField('message')"/>
              <nb-select fullWidth
                         [placeholder]="'app.shared.userProfile.field.message.placeholder'|translate"
                         [nbTooltip]="'app.shared.userProfile.field.message.tooltip'|translate"
                         size="small" formControlName="message" id="message"
                         [status]="statusField('message')">
                <nb-option *ngFor="let message of messageTimeOut;" [value]="message">{{ message }}</nb-option>
              </nb-select>
            </section>
            <section class="grid">
              <div class="col-6">
                <mkad-form-label
                  controlId="language"
                  [text]="'app.shared.userProfile.field.language.label' | translate"
                  [tooltip]="'app.shared.userProfile.field.language.tooltip' | translate"
                  [status]="statusField('language')"/>
                <nb-select fullWidth
                           [placeholder]="'app.shared.userProfile.field.language.placeholder'|translate"
                           [nbTooltip]="'app.shared.userProfile.field.language.tooltip'|translate"
                           size="small" formControlName="language" id="language"
                           [status]="statusField('language')">
                  <nb-option *ngFor="let language of languageList" [value]="language.id">{{ language.name }}</nb-option>
                </nb-select>
              </div>
              <div class="col-6">
                <mkad-form-label
                  controlId="theme"
                  [text]="'app.shared.userProfile.field.theme.label' | translate"
                  [tooltip]="'app.shared.userProfile.field.theme.tooltip' | translate"
                  [status]="statusField('theme')"/>
                <nb-select fullWidth
                           [placeholder]="'app.shared.userProfile.field.theme.placeholder' | translate"
                           [nbTooltip]="'app.shared.userProfile.field.theme.tooltip'|translate"
                           size="small" formControlName="theme" id="theme"
                           [status]="statusField('theme')">
                  @for (theme of themeList; track theme.id) {
                    <nb-option [value]="theme.id">{{ theme.name }}</nb-option>
                  }
                </nb-select>
              </div>
            </section>
            <section class="field__wrap gap-2">
              <nb-toggle labelPosition="start" class="mkad-toggle-split" id="keepLogin" status="success"
                         formControlName="multipleSession" nbTooltipPlacement="right"
                         [nbTooltip]="'app.shared.userProfile.field.multipleSession.tooltips' | translate">
                {{ 'app.shared.userProfile.field.multipleSession.label'| translate }}
              </nb-toggle>
              <nb-toggle labelPosition="start" class="mt-2 mkad-toggle-split" id="multipleSession" status="success"
                         formControlName="keepLogin" nbTooltipPlacement="right"
                         [nbTooltip]="'app.shared.userProfile.field.keepLogin.tooltips' | translate">
                {{ 'app.shared.userProfile.field.keepLogin.label'| translate }}
              </nb-toggle>
              <nb-toggle labelPosition="start" class="mt-2 mkad-toggle-split" id="securePassword" status="success"
                         formControlName="securePassword" nbTooltipPlacement="right"
                         [nbTooltip]="'app.shared.userProfile.field.securePassword.tooltips'|translate">
                {{ 'app.shared.userProfile.field.securePassword.label'|translate }}
              </nb-toggle>
            </section>
          </div>
        </nb-tab>
      </nb-tabset>
    </form>
  }
  <ng-container footer>
    <button nbButton outline size="small" status="danger" type="button" (click)="onClickClose(null)"
            [nbTooltip]="'app.shared.userProfile.button.cancel.tooltip' | translate">
      {{ 'app.shared.userProfile.button.cancel.label' | translate }}
    </button>
    <button nbButton outline size="small" status="success" type="submit" (click)="onClickSubmit()"
            [disabled]="!isFormValid()" [nbTooltip]="'app.shared.userProfile.button.save.tooltip' | translate">
      {{ 'app.shared.userProfile.button.save.label' | translate }}
    </button>
  </ng-container>
</mkad-wrapper-dialog>
