import {Component, DestroyRef, inject, OnDestroy, OnInit} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {
  NbButtonModule,
  NbCardModule,
  NbDialogRef,
  NbIconModule,
  NbSpinnerModule,
  NbThemeService,
  NbTooltipModule,
} from '@nebular/theme';
import {AsyncPipe, NgOptimizedImage} from '@angular/common';
import {RouterLink} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {MkadIconCloseComponent} from '../../global';
import {LegalContentDialogComponent} from '../../main';
import {AboutUsService} from '@shared/services';
import {MkadBaseComponent} from '@shared/components';
import {AboutUsDataInterface} from '@shared/interface';
import {DEFAULT_ABOUT_US_DATA} from '../../../../auth/tokens/default-about-us-data.token';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Observable} from 'rxjs';

@Component({
  selector: 'mkad-about-us',
  templateUrl: './about-us.component.html',
  standalone: true,
  providers: [AboutUsService],
  imports: [
    TranslateModule,
    RouterLink,
    NbCardModule,
    NbIconModule,
    NbTooltipModule,
    NbSpinnerModule,
    NbButtonModule,
    MkadIconCloseComponent,
    NgOptimizedImage,
    AsyncPipe,
  ],
})
export class MkadAboutUsComponent extends MkadBaseComponent<MkadAboutUsComponent> implements OnInit, OnDestroy {
  private readonly aboutUsService = inject(AboutUsService);
  aboutUsData: AboutUsDataInterface | null = null;
  frontVersion = environment['version'];
  private readonly defaultAppSettings = inject(DEFAULT_ABOUT_US_DATA);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  override toTranslate = {
    'app.footer.aboutUs': '',
  };

  /**
   * @description
   * Constructor class
   * @param translate TranslateService dependency injection
   * @param dialogRef NbDialogRef dependency injection
   * @param theme NbThemeService dependency injection
   */
  constructor(override translate: TranslateService,
              override dialogRef: NbDialogRef<MkadAboutUsComponent>,
              private readonly theme: NbThemeService) {
    super(translate, dialogRef);
  }

  /**
   * @description
   * Method that response the new theme
   */
  get onChangeTheme(): Observable<{ name: string }> {
    return this.theme.onThemeChange();
  }

  /**
   * @description
   * Method that response to OnInit life cycle
   */
  override ngOnInit(): void {
    super.ngOnInit();
    this.getAboutUsData();
  }

  /**
   * @description
   * Method that get the about us data
   * @private
   */
  private getAboutUsData(): void {
    this.aboutUsData = this.defaultAppSettings;
    this.aboutUsData.description = this.translate.instant('app.main.about.dataBurned.description');
    this.aboutUsService.getAboutUsData()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          if (response) {
            this.aboutUsData = {...response};
          }
        },
      });
  }

  /**
   * @description
   * Method that response to OnInit life cycle
   */
  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  /**
   * @description
   * Method that closes the modal
   */
  onClickClose(): void {
    this.dialogRef.close();
  }

  /**
   * @description
   * Method that open a component like a modal
   */
  onClickOpenTermsAndConditions(): void {
    this.openDialog(LegalContentDialogComponent, {
      context: {
        data: this.aboutUsData ? this.aboutUsData.disclaimer : null,
      },
    });
  }

  /**
   * @description
   * Method that open a component like a modal
   */
  onClickOpenPolicy(): void {
    this.dialog.open(LegalContentDialogComponent, {
      context: {
        data: this.aboutUsData ? this.aboutUsData.policy : null,
      },
    });
  }

  /**
   * @description
   * Method that return the logo url
   */
  protected logoPath(theme: string): string {
    return `assets/image/meerkad_${theme !== 'corporate' ? 'dark' : 'light'}.webp`;
  }

}
