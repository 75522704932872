/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         1/02/24 - 11:26
 * Module name:  menu.service
 * File name:    menu.service.ts
 * IDE:          WebStorm
 */

import {inject, Injectable} from '@angular/core';
import {NbMenuBag, NbMenuItem, NbMenuService} from '@nebular/theme';
import {MENU_MAIN_CONST, MENU_MAIN_TAG, MENU_OVERLAY_TAG, MENU_USER_OVERLAY} from '../../constants';
import {BehaviorSubject, Subject} from 'rxjs';
import {MenuComponentConfigEnum, MenuComponentOverlayEnum} from '../../enums';
import {TranslateService} from '@ngx-translate/core';
import {UserLevelEnum} from '@zibanu/auth';

@Injectable()
export class MkadMenuManagerService {
  readonly translate = inject(TranslateService);
  readonly nbMenu = inject(NbMenuService);
  private mainMenu: NbMenuItem[] = [];
  private overlayMenu: NbMenuItem[] = [];
  readonly mainMenu$ = new BehaviorSubject<NbMenuItem[]>(this.mainMenu);
  readonly overlayMenu$ = new BehaviorSubject<NbMenuItem[]>(this.overlayMenu);
  private readonly logout$$ = new Subject<null>();
  private readonly change$$ = new Subject<null>();
  private readonly about$$ = new Subject<null>();
  private readonly userSetting$$ = new Subject<null>();
  private readonly appSetting$$ = new Subject<null>();
  private _userLevel = UserLevelEnum.Guest;
  get userLevel(): number {
    return this._userLevel;
  }

  set userLevel(value: number) {
    this._userLevel = value;
  }

  /**
   * @description
   * Constructor class
   */
  constructor() {
    this.onClickMenuListener();
  }

  /**
   * @description
   * Method that refresh the items in the main sidebar menu
   * @param level current user level
   */
  reloadMainMenu(level: number): void {
    this.userLevel = level;
    const localMainMenu: NbMenuItem[] = structuredClone(MENU_MAIN_CONST);
    const mainMenuFiltered = localMainMenu.filter((item) => level <= item.data.level);
    this.setMainMenu(this.getTranslateMap(mainMenuFiltered, ['title']));
    this.mainMenu$.next(this.mainMenu);
    this.reloadOverlayMenu();
  }


  /**
   * @description
   * Method that refresh the items in the overlay menu
   */
  reloadOverlayMenu() {
    const localOverlayMenu: NbMenuItem[] = structuredClone(MENU_USER_OVERLAY);
    this.setOverlayMenu(this.getTranslateMap([...localOverlayMenu], ['title']));
    this.overlayMenu$.next(this.overlayMenu);
  }

  /**
   * @description
   * Method that valid the click event in the item of the sidebar
   */
  private onClickMenuListener() {
    this.nbMenu.onItemClick().subscribe({
      next: (itemPressed: NbMenuBag) => {
        const {item, tag} = itemPressed;
        if (!item.data) return;
        if (tag === MENU_MAIN_TAG) {
          if (item.data.component == MenuComponentConfigEnum.MkadLogoutComponent) this.logout$$.next(null);
          if (item.data.component == MenuComponentConfigEnum.MkadAboutUsComponent) this.about$$.next(null);
        } else if (tag === MENU_OVERLAY_TAG) {
          if (item.data.component == MenuComponentOverlayEnum.UserProfile) this.userSetting$$.next(null);
          if (item.data.component == MenuComponentOverlayEnum.AppPreferences) this.appSetting$$.next(null);
          if (item.data.component == MenuComponentOverlayEnum.ChangePassword) this.change$$.next(null);
          if (item.data.component == MenuComponentOverlayEnum.Logout) this.logout$$.next(null);
        }
      },
    });
  }

  /**
   * @description
   * Method that set the data for the main menu
   * @param newMenu new data to set
   */
  private setMainMenu(newMenu: NbMenuItem[]): void {
    this.mainMenu = newMenu;
  }

  /**
   * @description
   * Method that set the data for the overlay menu
   * @param newMenu new data to set
   */
  private setOverlayMenu(newMenu: NbMenuItem[]): void {
    this.overlayMenu = newMenu;
  }

  /**
   * @description
   * Method that returns any change in the main menu
   */
  onChangeMainMenu() {
    return this.mainMenu$;
  }

  /**
   * @description
   * Method that returns any change in the overlay menu
   */
  onChangeOverlayMenu() {
    return this.overlayMenu$;
  }


  /**
   * @description
   * Method that return the logout observable
   */
  onClickLogout() {
    return this.logout$$;
  }

  /**
   * @description
   * Method that return the user setting observable
   */
  onClickUserSetting() {
    return this.userSetting$$;
  }

  /**
   * @description
   * Method that return the app setting observable
   */
  onClickAppSetting() {
    return this.appSetting$$;
  }

  /**
   * @description
   * Method that return the about us observable
   */
  onClickAboutUs() {
    return this.about$$;
  }

  /**
   * @description
   * Method that return the change password observable
   */
  onClickChangePassword() {
    return this.change$$;
  }

  /**
   * @description
   * Method that translates the menu dynamically
   * @param list list you want to translate
   * @param keyToTranslate keys of the object to be translated
   */
  getTranslateMap<T = object>(list: T[], keyToTranslate: string[]) {
    list.forEach((item: T, itemIndex: number): void => {
      keyToTranslate.forEach((keyItem: string): void => {
        this.translate.get(item[keyItem as keyof T] as string).subscribe((result: string): void => {
          list[itemIndex][keyItem as keyof T] = result as T[keyof T];
        });
      });
    });
    return list;
  }
}
