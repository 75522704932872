import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {ZbAuthManagerService, ZbAuthToken} from '@zibanu/auth';
import {Observable, switchMap} from 'rxjs';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NbToastrService} from '@nebular/theme';
import {PreferencesService} from '@shared/services';

export const MILLISECONDS_IN_MINUTES = 1000;
export const MINUTES_IN_HOUR = 60;

const HALF_HOUR = 30;
const getRefreshDateDeadline = (time: number) => {
  // the refresh should be done every half hour (30 minutes)
  const halfAnHour = (HALF_HOUR * MINUTES_IN_HOUR * MILLISECONDS_IN_MINUTES);
  return new Date(time - halfAnHour);
}
const GAB_BEFORE_LOGOUT = 5;
const getSessionDateDeadline = (time: number) => {
  // Logout must be done 55 minutes after logging in.
  const fiveMinutes = (GAB_BEFORE_LOGOUT * MINUTES_IN_HOUR * MILLISECONDS_IN_MINUTES);
  return new Date(time - fiveMinutes);
}

@Injectable()
export class SessionManagerInterceptor implements HttpInterceptor {
  private readonly errorMessage = 'app.shared.session.expiredToken';

  /**
   * Constructor class
   * @param authService ZbAuthManagerService dependency injection
   * @param preferences ZbAuthManagerService dependency injection
   * @param router router dependency injection
   * @param toast toast dependency injection
   * @param translate translate dependency injection
   */
  constructor(
    private readonly authService: ZbAuthManagerService,
    private readonly preferences: PreferencesService,
    private readonly router: Router,
    private readonly toast: NbToastrService,
    private readonly translate: TranslateService) {
  }

  /**
   * @description
   * Interceptor input method
   * @param request Http request
   * @param next Request triggering handler
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.getToken().pipe(
      switchMap((token: ZbAuthToken) => {
        if (token.isValid()) {
          const isLogoutRequest = request.url.includes('auth/logout');
          const isRefreshRequest = request.url.includes('j6UvfcGuSCbKDK65R');
          if (isLogoutRequest || isRefreshRequest) return next.handle(request);
          const tokenExpirationDate: Date = new Date(token.getPayload().exp * MILLISECONDS_IN_MINUTES);
          const currentDate: Date = new Date();
          const refreshDeadline: Date = getRefreshDateDeadline(tokenExpirationDate.getTime());
          const sessionDeadline: Date = getSessionDateDeadline(tokenExpirationDate.getTime());
          if (currentDate >= refreshDeadline) this.handleRefresh(token.getValue(), request, next);
          if (currentDate >= sessionDeadline) this.handleLogout();
        }
        return next.handle(request);
      }),
    );
  }

  /**
   * @description
   * Method that controls the closing of the session
   * @private
   */
  private handleLogout(): void {
    this.authService.doLogout().subscribe({
      next: (response) => {
        if (response.isSuccess()) {
          this.router.navigate(['/home'])
            .then(() => this.showError(this.translate.instant(this.errorMessage)));
        }
      },
      error: (errorResponse) => this.showError(errorResponse.error.toString()),
    });
  }

  /**
   * @description
   * Method that controls token refreshing
   * @param token current token
   * @param request Http request
   * @param next Request triggering handler
   * @private
   */
  private handleRefresh(token: string, request: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.preferences.userSettings.keep_logged_in) return next.handle(request)
    return this.authService.refreshToken({token}).subscribe({
      next: () => {
        return next.handle(request)
      },
      error: (errorResponse) => this.showError(errorResponse.error.toString()),
    });
  }

  /**
   * @description
   * Method displaying the toast message with the error
   * @param message message to show
   * @private
   */
  private showError(message: string) {
    this.toast.danger(undefined, message);
  }
}

