<section class="w-full h-full flex flex-row gap-1 justify-content-between">
  <div class="flex flex-row align-items-center max-w-1/4 gap-4">
    <nb-icon tabindex="1" mkadFaSpin pack="far" icon="bars" class="cursor-pointer"
             (click)="clickToggleIcon.emit()"
             [nbTooltip]="'app.header.menuIcon.tooltip' | translate"
             [attr.aria-label]="'app.header.menuIcon.aria' | translate"/>
    @if (customerData()?.customer_logo) {
      <img class="h-full w-auto" [src]="parseString(customerData()?.customer_logo)"
           [alt]="'app.header.logo.alt' | translate"
           height="60" width="180">
    } @else if (customerData()?.customer_name) {
      <span class="alert_inline_content white-space-nowrap h-min align-self-center font-bold">
        {{ parseString(customerData()?.customer_initials) }}
      </span>
    } @else {
      <a [attr.href]="getUrl" target="_blank" class="layout_footer_img">
        <img class="h-full w-auto" ngSrc="../../../../../assets/image/cq-logo.png"
             [alt]="'app.header.logo.alt' | translate"
             height="63"
             width="187">
      </a>
    }
  </div>
  <mkad-status-bar class="flex gap-1 w-3"/>
  <div class="flex flex-row">
    @if (isActive && perpetualSession) {
      <nb-icon class="icon__badge" icon="user-check" status="success"
        [nbTooltip]="'app.header.user.keep_logged_in' | translate"
               nbTooltipPlacement="start"
      />
    }
    <nb-user cdkOverlayOrigin
             #overlayDispatch="cdkOverlayOrigin"
             [class]="' label__left cursor-pointer ' + customerContractStatusClass"
             [class.hidden]="!isActive"
             shape="round"
             [picture]="avatarPicture"
             [showInitials]="!userAvatar"
             [nbTooltip]="isActive ? '' : ('app.header.user.tooltip' | translate)"
             [nbTooltipDisabled]="isActive"
             [name]="isActive ? userName : ('app.header.user.guest' | translate)"
             (click)="onClickUserAvatar()"/>
    @if (!isActive) {
      <nb-user class="label__left cursor-pointer"
               shape="round"
               [showInitials]="true"
               [nbTooltip]="'app.header.user.tooltip' | translate"
               [name]="'app.header.user.guest' | translate"
               (click)="clickAvatar.emit()"/>
    }
    <ng-template cdkConnectedOverlay cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
                 cdkConnectedOverlayPanelClass="frame_header"
                 [cdkConnectedOverlayHasBackdrop]="true" [cdkConnectedOverlayOrigin]="overlayDispatch"
                 [cdkConnectedOverlayOpen]="showOverlay"
                 (detach)="showOverlay = false" (backdropClick)="showOverlay = false">
      <nb-card cdkTrapFocus [cdkTrapFocusAutoCapture]="true" class="m-0">
        <mkad-user-overlay (clickOverlay)="onClickOverlay()"/>
      </nb-card>
    </ng-template>
  </div>
</section>
