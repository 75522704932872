<nb-card>
  <nb-card-body class="w-30rem relative" [nbSpinner]="aboutUsData === null">
    <mkad-icon-close (clickClose)="onClickClose()"/>
    <section class="flex flex-column gap-3 min-w-10rem">
      @if (aboutUsData) {
        <div class="flex max-h-10rem">
          @if (onChangeTheme | async; as data) {
            <img class="h-auto w-full" [ngSrc]="logoPath(data.name)"
                 [alt]="'app.header.logo.alt' | translate"
                 height="403"
                 width="1080">
          }

        </div>
        <div class="flex flex-column">
          <div [innerHTML]="(aboutUsData.description | translate)"></div>
          <div class="grid align-items-center">
            @if (frontVersion) {
              <small class="col">
                {{ 'app.main.about.version.frontend' | translate }}
                <strong>{{ frontVersion }}</strong>
              </small>
            }
            @if (aboutUsData.back_version) {
              <small class="col">
                {{ 'app.main.about.version.backend' | translate }}
                <strong>{{ aboutUsData.back_version }}</strong>
              </small>
            }
          </div>
          <div class="grid mt-1">
            <div class="col-6">
              <a href="{{ aboutUsData.url}}" target="_blank" class="no-underline about-link"
                 [attr.aria-label]="'app.main.about.url' | translate">
                <nb-icon icon="globe" class="vertical-align-middle"/>
                {{ 'app.main.about.url' | translate }}
              </a>
            </div>
            <div class="col-6">
              <a href="mailto:{{ aboutUsData.email}}" class="no-underline about-link">
                <nb-icon icon="envelope" class="vertical-align-middle"/>
                {{ aboutUsData.email }}
              </a>
            </div>
            <div class="col-6">
              <a routerLink="" (click)="onClickOpenTermsAndConditions()"
                 class="no-underline cursor-pointer about-link"
                 [attr.aria-label]="'app.main.about.policies.privacyPolicy' | translate">
                <nb-icon icon="file-contract" class="vertical-align-middle"/>
                {{ 'app.main.about.policies.privacyPolicy' | translate }}
              </a>
            </div>
            <div class="col-6">
              <a (click)="onClickOpenPolicy()"
                 class="no-underline cursor-pointer about-link"
                 [attr.aria-label]="'app.main.about.policies.conditions' | translate">
                <nb-icon icon="file-contract" class="vertical-align-middle"/>
                {{ 'app.main.about.policies.conditions' | translate }}
              </a>
            </div>
          </div>
        </div>
        <div class="flex align-items-center justify-content-end mt-1 gap-2">
          <a [href]="aboutUsData.facebook" nbButton ghost target="_blank" class="p-0">
            <nb-icon [nbTooltip]="'app.main.about.network.tooltip.facebook' | translate" pack="fab"
                     icon="square-facebook" class="text-3xl logo-facebook w-auto h-auto"/>
          </a>
          <a [href]="aboutUsData.linkedin" nbButton ghost target="_blank" class="p-0">
            <nb-icon [nbTooltip]="'app.main.about.network.tooltip.linkedin' | translate" pack="fab"
                     icon="linkedin" class="text-3xl logo-linkedin w-auto h-auto"/>
          </a>
          <a [href]="aboutUsData.instagram" nbButton ghost target="_blank" class="p-0">
            <nb-icon [nbTooltip]="'app.main.about.network.tooltip.instagram' | translate" pack="fab"
                     icon="instagram" class="text-3xl logo-instagram w-auto h-auto"/>
          </a>
        </div>
      }
    </section>
  </nb-card-body>
</nb-card>

